@charset "UTF-8";

/*----------------------------------------
g-footer
----------------------------------------*/

.g-footer {
  display: block;
  background-color: $primary;
  color: $white;
  position: relative;
  z-index: 0;
  transition: $transition;

  [data-modal="active"] & {
    z-index: -1;
  }

  &-cta {
    position: relative;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $side-padding;

    @include is-mobile {
      height: 240px;
      padding: 15px;
    }

    &-bg.c-figure {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .c-desc {
      color: $white;
      position: relative;
      font-family: $font-min;
      z-index: 1;
      line-height: 2;
      text-align: center;
    }

    .c-desc + .c-btn {
      margin-top: 20px;
    }

    .c-btn {
      position: relative;
      z-index: 1;
    }
  }

  &-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px $side-padding;
    min-height: 110px;

    @include is-humberger {
      padding-left: 40px;
    }

    @include is-mobile {
      padding: 30px 16px;
      display: block;
    }
  }

  &-nav {
    flex-grow: 2;

    @include is-humberger {
      display: none;
    }

    &-items {
      padding: 0 0 0 46px;
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      @include fz(12);
      padding-left: 15px;


      &:last-child {
        padding-right: 0;
      }

      &-link {
        padding: 2px 4px;
        display: inline-block;
        transition: $transition;
        color: $white;

        &:hover {
          @include is-laptop {
            opacity: $opacity;
          }
        }
      }
    }
  }

  &-sns {
    display: flex;
    justify-content: flex-end;

    @include is-mobile {
      justify-content: flex-start;
    }

    &-item {
      @include fz(13);
      display: flex;
      align-items: center;
      justify-content: center;

      & + & {
        margin-left: 16px;
      }

      &-link {
        padding: 2px;
        transition: $transition;

        &:hover {
          @include is-laptop {
            opacity: $opacity;
          }
        }
      }

      &-icon {
        line-height: 1;

        &.-insta {
          width: 16px;
          min-width: 16px;
        }

        &.-line {
          width: 16px;
          min-width: 16px;
        }

        svg {
          fill: $white;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-logo {
    width: 122px;
    min-width: 122px;
    padding-top: 12px;

    @include is-humberger {
      padding-top: 0;
    }

    &-link {
      display: block;
      transition: $transition;

      &:hover {
        @include is-laptop {
          opacity: $opacity;
        }
      }
    }
  }

  &-info {
    width: 120px;
    padding-top: 5px;

    @include is-humberger {
      flex-grow: 2;
      padding-top: 0;
    }

    @include is-mobile {
      padding-top: 16px;
    }
  }

  &-copyright {
    @include fz(13);
    @include ls(2.4);
    font-family: $font-en;
    font-weight: 500;
    text-align: right;

    @include is-mobile {
      text-align: left;
    }
  }

  &-sns + &-copyright {
    padding-top: 6px;
  }
}
