@charset "UTF-8";

/*----------------------------------------
g-header
----------------------------------------*/

.l-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: $transition;


  [data-status="loading"] & {
    @include is-laptop {
      padding-right: 10px;
    }
  }

  [data-menu="active"] & {
    opacity: 1;
    pointer-events: all;
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  &.is-lower {
    opacity: 1;
    pointer-events: all;
  }
}

.g-header {
  &-inner {
    min-height: $header-height;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 72px 0 40px;
    background-color: rgba($sub, .8);
    // opacity: .8;
    transition: $transition;

    @include is-humberger {
      padding-right: $header-height;
    }

    @include is-mobile {
      min-height: $header-height-sp;
      padding: 0 $header-height-sp 0 $side-padding-mobile;
    }

    [data-menu="active"] & {
      background-color: $sub;
    }
  }

  &-info {
    flex-grow: 1;
  }

  &-logo {
    width: 132px;
    line-height: 0;

    @include is-mobile {
      width: 105px;
    }

    &-link {
      display: inline-block;
      transition: $transition;

      &:hover {
        @include is-laptop {
          opacity: $opacity;
        }
      }
    }
  }

  &-nav {
    display: flex;
    padding-right: 40px;

    @include is-humberger {
      display: block;
      position: fixed;
      background-color: $primary;
      top: -50px;
      right: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      z-index: -1;
      margin-left: auto;
      overflow-y: auto;
      padding: $header-height 0;
      opacity: 0;
      transition: $transition;
      pointer-events: none;
    }

    @include is-mobile {
      padding: $header-height-sp 0;
    }

    [data-menu="active"] & {
      top: 0;
      opacity: 1;
      pointer-events: all;
    }

    &-items {
      display: flex;
      align-items: center;

      @include is-humberger {
        display: block;
        border-bottom: 1px solid $white;
      }
    }

    &-item {
      padding-left: 10px;

      @include is-humberger {
        width: 100%;
        padding: 0;
        border-top: 1px solid $white;
      }

      &-link {
        @include ls(2);
        @include fz(13);
        font-weight: 500;
        text-align: center;
        line-height: 1.25;
        display: inline-block;
        padding: 8px 10px;
        transition: $transition;
        position: relative;

        @include is-humberger {
          color: $white;
          display: block;
          text-align: left;
          line-height: 2;
          padding: 20px 80px 20px 40px;
        }

        @include is-mobile {
          padding: 12px 40px 12px 16px;
        }

        &:hover {
          @include is-laptop {
            opacity: $opacity;
          }
        }

        &::after {
          position: absolute;
          top: calc(50% + 2px);
          right: 40px;
          width: 8px;
          height: 8px;
          border: 1px solid $white;
          transform: rotate(45deg) translate(-50%, -50%);
          border-left: 0;
          border-bottom: 0;
          display: none;

          @include is-humberger {
            content: "";
            display: block;
          }

          @include is-mobile {
            right: 28px;
          }
        }
      }
    }
  }

  // &-spnav {
  //   &-items {
  //     margin-top: 40px;
  //     display: none;

  //     @include is-tablet {
  //       display: block;
  //     }
  //   }

  //   &-item {
  //     @include fz(13);
  //     text-align: center;

  //     &-link {
  //       display: inline-block;
  //       padding: 4px 20px;
  //     }

  //   }
  // }

  &-sns {
    &-items {
      display: flex;

      @include is-humberger {
        margin-right: 27px;
      }
    }

    &-item {
      @include fz(13);
      display: flex;
      align-items: center;
      justify-content: center;

      & + & {
        margin-left: 8px;
      }

      &-link {
        padding: 8px;
        transition: $transition;

        &:hover {
          @include is-laptop {
            opacity: $opacity;
          }
        }
      }

      &-icon {
        line-height: 1;

        &.-insta {
          width: 20px;
          min-width: 20px;
        }

        &.-line {
          width: 20px;
          min-width: 20px;
        }

        svg {
          fill: $primary-txt;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
