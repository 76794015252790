@charset "UTF-8";

/*----------------------------------------
privacy
----------------------------------------*/

.p-privacy {
  padding: 74px 0 80px;

  @include is-mobile {
    padding: 30px 0 80px;
  }

  &-inner {
    max-width: 1000px;
    margin: 0 auto;
  }

  &-section {
    background-color: $sub;
    padding: 50px 40px 80px;

    @include is-mobile {
      padding: 25px 15px;
    }
  }

  &-lead {
    padding-bottom: 10px;
  }

  &-block {
    margin: 52px 0 0;

    @include is-mobile {
      margin-top: 15px;
    }
  }

  &-desc {
    margin: 0;
    padding: 10px 0 0;

    a {
      text-decoration: underline;
    }
  }

  &-list {
    list-style-type: decimal;
    padding: 10px 0 10px 1.5em;

    .-c-list {
      margin: 0;
      padding-left: 0;
      counter-reset: c-list;
      list-style: none;
    }

    .-c-u-list {
      margin: 0;
      padding-left: 0;
      padding-top: 4px;
      counter-reset: c-u-list;
      list-style: none;
    }

    &-item {
      @include ls(1.8);
      position: relative;

      .-c-list & {
        list-style: none;
        margin: 0;
        padding-left: 2.4em;
        counter-increment: c-list;

        &::before {
          position: absolute;
          left: 0;
          content: "(" counter(c-list) ")";
        }
      }

      .-c-u-list & {
        list-style: none;
        margin: 0;
        padding-left: 2.4em;
        counter-increment: c-u-list;

        &::before {
          position: absolute;
          left: 0;
          content: "[" counter(c-u-list) "]";
        }
      }
    }
  }
}
