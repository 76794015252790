@charset "UTF-8";

/*----------------------------------------
voice
----------------------------------------*/

.p-voice {
  padding: 74px 0 80px;

  @include is-mobile {
    padding: 30px 0 80px;
  }

  &-inner {
    max-width: 1120px;
    margin: 0 auto;
  }

  &-section {
    background-color: $sub;
    padding: 60px;
    display: flex;

    @include is-tablet {
      display: block;
    }

    @include is-mobile {
      padding: 15px 15px 18px;
    }

    & + & {
      margin-top: 60px;

      @include is-mobile {
        margin-top: 30px;
      }
    }
  }

  &-figure {
    width: percentage(640 / 2000);

    @include is-tablet {
      width: 100%;
    }

    .c-figure {
      padding-bottom: percentage(800 / 640);
    }
  }

  &-cnt {
    width: percentage(1360 / 2000);
    padding: 0 0 0 60px;

    @include is-tablet {
      width: 100%;
      padding: 40px 0 0;
    }

    @include is-mobile {
      padding-top: 25px;
    }
  }

  &-desc {
    padding-top: 30px;

    @include is-mobile {
      padding-top: 20px;
    }
  }
}
