@charset "UTF-8";

/*----------------------------------------
blog
----------------------------------------*/

.p-blog {
  $this: &;
  display: block;
  padding: 74px $side-padding 80px;

  @include is-mobile {
    padding: 32px $side-padding-mobile 80px;
  }

  &-archive {
    .l-blog {
      margin: auto;
      max-width: 1000px;
      display: flex;
      flex-direction: row-reverse;

      @include is-tablet {
        display: block;
      }

      #{$this}-main {
        width: 760px;

        @include is-tablet {
          width: 100%;
          margin-top: 60px;

          @include is-mobile {
            margin-top: 30px;
          }
        }

        &-inner {
          padding: 30px 60px;

          @include is-mobile {
            padding: 15px;
          }
        }
      }

      &-side {
        width: 240px;
        padding-left: 40px;

        @include is-tablet {
          width: 100%;
          padding: 0;
        }
      }
    }
  }

  &-single {
    .l-blog {
      margin: auto;
      max-width: 1000px;

      #{$this}-main {
        width: 100%;

        &-inner {
          padding: 60px;

          @include is-mobile {
            padding: 30px 15px;
          }
        }
      }
    }
  }

  &-main {
    &-inner {
      background-color: $sub;
    }
  }

  &-category {
    &-pc {
      @include is-tablet {
        display: none;
      }
    }

    &-sp {
      display: none;

      @include is-tablet {
        display: block;
        margin-top: 30px;
      }

      @include is-mobile {
        margin-top: 20px;
      }
    }

    &-item {
      padding-top: 15px;

      & + & {
        padding-top: 6px;
      }

      &-link {
        transition: $transition;

        .is-current & {
          color: $primary-txt;
          pointer-events: none;
        }

        &:hover {
          @include is-laptop {
            opacity: $opacity;
          }
        }

      }
    }
  }

  &-posts {
    display: block;
  }

  &-post {
    & + & {
      border-top: 1px solid $primary-txt;
    }

    .c-post {
      &-ttl {
        @include line-clamp(1);
        color: $primary-txt;
      }
    }
  }
}


/*----------------------------------------
post
----------------------------------------*/

.c-post {

  // post archive
  // ------------------------

  // &-item {
  //   & + & {
  //     border-top: 1px solid currentColor;
  //   }
  // }

  &-link {
    padding: 25px 0;
    display: flex;
    transition: $transition;

    &:hover {
      @include is-laptop {
        background-color: rgba($white, $opacity);
      }
    }
  }

  &-thumb {
    width: 150px;

    @include is-mobile {
      width: 112px;
    }

    .c-figure {
      padding-bottom: percentage(240 / 300);
    }
  }

  &-cnt {
    width: calc(100% - 150px);
    padding: 15px 0 15px 30px;

    @include is-tablet {
      padding: 0 0 0 30px;
    }

    @include is-mobile {
      width: calc(100% - 122px);
      padding: 0 0 0 16px;
    }
  }

  &-ttl {
    @include fz(18);
    padding-top: 6px;
    font-family: $font-min;
    font-weight: $medium;

    @include is-mobile {
      @include fz(15);
      padding-top: 2px;
    }
  }

  // common
  // ------------------------

  &-meta {
    display: flex;
    align-items: flex-start;

    @include is-tablet {
      display: block;
    }
  }

  &-date {
    @include fz(17);
    @include ls(1.3);
    font-family: $font-en;
    margin-right: 16px;

    @include is-mobile {
      @include fz(15);
    }
  }

  &-cat {
    display: flex;
    flex-wrap: wrap;

    &-name {
      @include fz(12);
      @include ls(1.4);
      border: 1px solid currentColor;
      margin: 6px 12px 6px 0;
      padding: 0 8px;
      color: $primary-txt;
      transition: $transition;

      @include is-mobile {
        @include fz(11);
        margin: 0 8px 4px 0;
      }

      &.is-link {
        &:hover {
          @include is-laptop {
            opacity: $opacity;
          }
        }
      }
    }
  }

  // post single
  // ------------------------

  &-postname {
    @include fz(20);
    width: 100%;
    font-family: $font-min;
    border: 1px solid $primary-txt;
    color: $primary-txt;
    border-right: 0;
    border-left: 0;
    margin-top: 12px;
    line-height: 1.7;
    padding: 12px 0;
  }

  &-eyecatch {
    line-height: 0;
    margin: 25px 0;

    @include is-mobile {
      margin: 20px 0;
    }

    &-bg {
      width: 100%;
      padding-top: percentage(1200 / 1820);
      display: block;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  &-content {
    line-height: 2;
  }
}

.c-post-content {
  > [class|="wp"] {
    margin: 40px 0 0;

    @include is-mobile {
      margin: 20px 0 0;
    }
  }

  > p:not([class]) {
    margin: 1em 0 0;
  }

  .wp-block-heading {
    color: $primary-txt;
    font-family: $font-min;
    line-height: 1.6;
    position: relative;
  }

  h2 {
    @include fz(21);
    border: 1px solid $primary-txt;
    border-right: 0;
    border-left: 0;
    padding: 8px 10px;
  }

  h3 {
    @include fz(19);
    padding: 0 0 0 10px;
    border-left: 6px solid $primary;
  }

  h4 {
    @include fz(17);
    border-bottom: 1px solid currentColor;
    padding: 0 0 6px;
  }

  h5 {
    @include fz(16);
    padding-left: 18px;

    &::before {
      content: "";
      position: absolute;
      top: calc(.5em * 1.6);
      left: 0;
      width: 10px;
      height: 2px;
      background-color: $primary;
    }
  }

  h6 {
    @include fz(15);
  }

  > ol:not([class]) {
    counter-reset: order 0;
    margin: 40px 0 0;
    padding: 0 0 0 1em;

    @include is-mobile {
      margin: 24px 0 0;
    }

    > li:not([class]) {
      line-height: 1.5;
      position: relative;
      padding-left: 2.4em;

      &::before {
        @include fz(17);
        @include ls(0);
        line-height: 1.25;
        font-family: $font-en;
        align-self: center;
        color: $primary-txt;
        content: counter(order, decimal-leading-zero) ".";
        counter-increment: order 1;
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
      }

      + li:not([class]) {
        margin: .5em 0 0;
      }
    }
  }

  > ul:not(.wp-block-gallery) {
    margin: 40px 0 0;
    padding: 0 0 0 1em;

    @include is-mobile {
      margin: 24px 0 0;
    }

    > li:not([class]) {
      position: relative;
      padding-left: 1.6em;

      &::before {
        align-self: center;
        background: $primary;
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        left: 0;
        top: .7em;
        position: absolute;
      }

      + li:not([class]) {
        margin: .2em 0 0;
      }
    }
  }

  b,
  strong {
    &:not([class]) {
      font-weight: bold;
      background: linear-gradient(transparent 55%, $main-bg 45%);
    }
  }

  em {
    &:not([class]) {
      font-style: italic;
    }
  }

  .wp-block {
    &-image {
      overflow: hidden;
      position: relative;

      &::after {
        content: "";
        display: block;
        padding-top: 60%;
      }

      img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        font-family: "object-fit: cover;", sans-serif;
        object-fit: cover;
      }

      figcaption {
        @include fz(14);
        font-family: $font-en;
        color: $white;
        background: rgba($black, .3);
        line-height: 1.5;
        margin: 0;
        padding: 1em 1.5em;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
      }
    }

    &-quote {
      @include fz(16);
      padding: 30px 20px;
      border: 1px solid $primary;
      background: $white;

      @include is-mobile {
        padding: 18px 20px;
      }

      cite {
        opacity: .8;
      }
    }

    &-audio {
      audio {
        @include is-mobile {
          min-width: 100%;
        }
      }

      figcaption {
        margin: .5em 0 0;
      }
    }

    // scss-lint:disable SelectorFormat
    &-file .wp-block-file__button {
      @extend %button-style;
      border-radius: 0;
      // background-color: $primary;
      // color: $white;
      // padding: 20px;
    }

    &-code {
      border: 0;
      border-radius: 0;
      background-color: $main-txt;
      color: $white;
      padding: 2em;
    }

    &-table {
      th,
      td {
        border-color: $primary-txt;
        background-color: $white;
      }
    }

    &-pullquote {
      padding: 40px 30px;
      border-color: $primary;
      color: $main-txt;

      p:not([class]) {
        @include fz(22);
        letter-spacing: .05em;
        color: $primary-txt;
        font-family: $font-en;

        @include is-mobile {
          @include fz(22);
        }
      }

      cite:not([class]) {
        @include fz(13);
      }
    }

    &-button {
      border: 1px solid $primary;

      &__link {
        @extend %button-style;
        @include fz(15);
        font-family: $font-min;
        background-color: $primary;
        color: $white;
        border-radius: 0;
        // scss-lint:disable ImportantRule
        padding: 12px !important;
        min-width: 160px;
        // scss-lint:disable ImportantRule
        border: 0 !important;
      }

      &.is-style-outline {
        color: $primary-txt;
        // scss-lint:disable ImportantRule
        border: 1px solid $primary-txt !important;
      }
    }

    &-column {
      > p:not(:first-child) {
        margin: 1em 0 0;
      }
    }

    &-cover {
      color: $white;
      font-family: $font-min;

      &__inner-container {
        color: $white;
      }
    }

    &-separator {
      border-color: $primary;
      margin-right: auto;
      margin-left: auto;

      &::before {
        color: $primary;
      }
    }

    &-calendar {
      caption {
        color: $main-txt;
        // color: $black;
      }

      th {
        color: $white;
        background: $primary;
        border-color: $primary;
      }

      td {
        color: $main-txt;
        border-color: $primary;
      }



      a {
        color: $primary-txt;
        display: block;
        padding: 5px 0;
        text-decoration: underline;
      }
    }

    &-embed__wrapper {
      text-align: center;
    }

    &-embed-youtube .wp-block-embed__wrapper {
      overflow: hidden;
      position: relative;

      &::after {
        content: "";
        display: block;
        padding-top: 56.25%;
      }

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  // scss-lint:disable SelectorFormat
  .wp-embed-aspect-4-3 .wp-block-embed-youtube .wp-block-embed__wrapper::after {
    padding-top: 75%;
  }

  // scss-lint:disable SelectorFormat
  .wp-embed-aspect-16-9 .wp-block-embed-youtube .wp-block-embed__wrapper::after {
    padding-top: 56.25%;
  }

  .wp-block-embed-twitter {
    text-align: center;

    .wp-block-embed__wrapper {
      width: 100%;
      max-width: 500px;
      margin: auto;
    }

    // scss-lint:disable ImportantRule
    .twitter-timeline {
      width: 100% !important;
    }
  }

  .c-post-eyecatch,
  .c-post-section__eyecatch,
  .c-post-section__txt {
    margin: 40px 0 0;

    @include is-mobile {
      margin: 24px 0 0;
    }
  }

  h3 + .c-post-section__txt {
    margin-top: 25px;

    @include is-mobile {
      margin-top: 15px;
    }
  }
}

/*----------------------------------------
addtoany
----------------------------------------*/

.addtoany_share_save_container {
  text-align: center;
  padding: 72px 0 35px;
  margin: 0;

  @include is-mobile {
    padding: 50px 0 35px;
  }
}

.addtoany_list {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include is-mobile {
    display: block;
  }
}

// scss-lint:disable ImportantRule
.a2a_kit .a2a_label {
  display: none !important;
}


/*----------------------------------------
toc
----------------------------------------*/
// scss-lint:disable IdSelector
div#toc_container {
  border: 0;
  background-color: $main-bg;
  margin-top: 40px;
  padding: 20px 30px;

  .toc_title {
    @include fz(19);
    font-family: $font-min;
    text-align: left;
    color: $primary-txt;
  }

  .toc_list {
    margin-top: 8px;
  }

  ul {
    margin: 0;
  }

  li {
    @include fz(16);
    // margin-bottom: 4px;

    a {
      transition: $transition;

      &:hover {
        text-decoration: none;
      }
    }

    ul {
      margin-bottom: 8px;

      li {
        @include fz(14);
        padding-left: 12px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          left: 0;
          border-radius: 50%;
          top: 12px;
          background-color: $primary;
        }
      }

      ul {
        padding-left: 0;
      }
    }

  }
}

.c-single-pager {
  margin: auto;
  text-align: center;
}
