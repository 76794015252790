
/*----------------------------------------
Variables
----------------------------------------*/

/*
# Color */
$black: #000;
$white: #fff;
$gray: #e6e6e6;

$primary: #d2bdaf;
$primary-dark: #957d6d;
$primary-txt: #b69681;

$sub: #f6ede7;
$sub-bg: #e8dad1;

$main-bg: #fbf8f6;
$main-txt: #888888;

/*
# Border */
$color-border: $gray;
/* $color-border: #bcbcbc; */

/*
# ScrollBar */
$color-track: #f1f1f1;
/* $color-track: #fff; */
$color-thumb: #adadad;
/* $color-thumb: #adadad; */

/*
# Shadow */
$shadow: rgba(#777, .1);
/* $shadow: rgba(#777, .1); */
$shadow-hover: rgba(#777, .3);
/* $shadow-hover: rgba(#777, .3); */

/*
# Hover */
$opacity: .5;
/* $opacity: .7; */

/*
# Transition */
$transition: .5s;
/* $transition: .3s; */

/*
# Font Family */
$font: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif; */
$font-min: 'Shippori Mincho', '游明朝体', YuMincho, '游明朝', 'Yu Mincho', 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN W3', HiraMinProN-W3, 'ヒラギノ明朝 ProN', 'Hiragino Mincho ProN', 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', 'HGS明朝E', serif;
/* $font-min: 'Shippori Mincho', '游明朝体', YuMincho, '游明朝', 'Yu Mincho', 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN W3', HiraMinProN-W3, 'ヒラギノ明朝 ProN', 'Hiragino Mincho ProN', 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', 'HGS明朝E', serif; */
$font-en: 'EB Garamond', serif;
/* font-en: 'EB Garamond', serif; */

// $font-cn: "Microsoft Yahei", "PingHei";
/* $font-cn: "Microsoft Yahei", "PingHei"; */
// $font-kr: "Malgun Gothic", "Yoon Gothic";
/* $font-kr: "Malgun Gothic", "Yoon Gothic"; */

/*
# Font Weight */
$thin: 200;
/* $thin: 200; */
$light: 300;
/* $light: 300; */
$regular: 400;
/* $regular:400; */
$medium: 500;
/* $medium: 500; */
$semibold: 600;
/* $semibold: 600; */
$bold: 700;
/* $bold: 700; */

/*
# Width */
$max-width: 1600px;
/* $max-width: 1600px; */
$content-width: 1440px;
/* $content-width: 1440px; */
$subpage-content-width: 1280px;
/* $subpage-content-width: 1280px; */

$side-padding: 80px;
$side-padding-mobile: 15px;

$header-height: 80px;
$header-height-sp: 60px;

/*
# Easing */
$ease: cubic-bezier(.19, 1, .22, 1);
/* $ease: cubic-bezier(.19, 1, .22, 1); */

/*

*/
