@charset "UTF-8";

/*----------------------------------------
index
----------------------------------------*/

.p-index {
  border-left: 0;
  border-right: 0;
}

.p-index-kv {
  &-inner {
    position: relative;
    line-height: 0;
  }

  &-slide {
    position: relative;

    &-item {
      height: 100vh;
    }

    &-figure {
      height: 100%;
    }

    &-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &::after {
      opacity: .4;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #957d6d;
      z-index: 1;
      pointer-events: none;
    }
  }

  &-logo {
    position: absolute;
    top: 50%;
    z-index: 1;
    max-width: 297px;
    left: 50%;
    transform: translate(-50%, -50%);

    @include is-mobile {
      max-width: 184px;
    }
  }

  &-scroll {
    position: absolute;
    z-index: 1;
    max-width: 297px;
    top: calc(100% - 100px);
    left: 50%;
    transform: translate(-50%, 0);
    color: $white;
    padding-bottom: 44px;

    &-txt {
      @include fz(15);
      @include ls(1.5);
      line-height: 1;
      font-family: $font-en;
      display: block;
    }

    &::before,
    &::after {
      animation: .7s scrolldown infinite alternate;
      content: "";
      position: absolute;
      width: 1px;
      bottom: 0;
      background-color: currentColor;
    }

    &::before {
      height: 35px;
      left: 50%;
    }

    &::after {
      height: 8px;
      left: calc(50% + 3px);
      transform: rotate(45deg);
    }
  }
}

.p-index-about {
  padding: 80px $side-padding 0;

  @include is-mobile {
    padding: 50px $side-padding-mobile 0;
  }

  &-inner {
    background-color: $sub;
    display: flex;
    padding: 80px;

    @include is-tablet {
      display: block;
    }

    @include is-mobile {
      padding: 15px 15px 50px;
    }

  }

  &-figure {
    width: percentage(1080 / 2560);

    @include is-tablet {
      width: 100%;
    }
  }

  .c-figure {
    padding-bottom: percentage(864 / 1296);
  }

  &-cnt {
    width: percentage(1480 / 2560);
    padding-left: 80px;

    @include is-tablet {
      width: 100%;
      padding-left: 0;
    }
  }

  .c-ttl-01 {
    margin-top: 20px;

    @include is-tablet {
      margin-top: 40px;
    }

    @include is-mobile {
      margin-top: 25px;
    }
  }

  .c-desc {
    margin-top: 30px;

    @include is-mobile {
      margin-top: 20px;
    }
  }

  .c-btn {
    margin-top: 40px;

    @include is-tablet {
      text-align: center;
    }

    @include is-mobile {
      margin-top: 30px;
    }
  }
}

.p-index-menu {
  padding: 0;

  &-inner {
    display: flex;
    flex-direction: row-reverse;
    padding: 100px 80px 0;

    @include is-tablet {
      display: block;
    }

    @include is-mobile {
      padding: 80px $side-padding-mobile 0;
    }
  }

  &-figure {
    width: percentage(1080 / 2560);

    @include is-tablet {
      width: 100%;
    }

    &-item {
      & + & {
        padding-top: 20px;

        @include is-mobile {
          padding-top: 15px;
        }
      }
    }
  }

  .c-figure {
    padding-bottom: percentage(440 / 1080);

    @include is-mobile {
      padding-bottom: percentage(280 / 686);
    }
  }

  &-cnt {
    width: percentage(1480 / 2560);
    padding-right: 80px;

    @include is-tablet {
      width: 100%;
      padding: 0;
    }
  }

  .c-ttl-01 {
    margin-top: 20px;

    @include is-tablet {
      margin-top: 40px;
    }

    @include is-mobile {
      margin-top: 25px;
    }
  }

  .c-desc {
    margin-top: 30px;

    @include is-mobile {
      margin-top: 20px;
    }
  }

  .c-btn {
    margin-top: 40px;

    @include is-tablet {
      text-align: center;
    }

    @include is-mobile {
      margin-top: 30px;
    }

    &-link {
      min-width: 280px;
    }
  }
}

.p-index-work {
  padding: 0;

  &-inner {
    display: flex;
    padding: 100px 80px;

    @include is-tablet {
      display: block;
    }

    @include is-mobile {
      padding: 80px $side-padding-mobile;
    }
  }

  &-figure {
    width: percentage(1080 / 2560);
    height: 100%;

    @include is-tablet {
      width: 100%;
    }

    &-items {
      &-ctrl {
        position: absolute;
        bottom: 40px;
        justify-content: flex-end;
        width: 100%;
        display: none;

        .swiper-container-initialized .swiper-wrapper + & {
          display: flex;
        }

        .swiper-button-prev,
        .swiper-button-next {
          position: relative;
          width: 60px;
          height: 60px;
          background-color: $primary;
          margin: 0;
          top: 0;
          left: 0;

          @include is-mobile {
            width: 48px;
            height: 48px;
          }

          &::before {
            content: "";
            width: 5px;
            height: 1px;
            background-color: $white;
            transform: rotate(-45deg);
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 10px);
          }

          &::after {
            content: "";
            width: 20px;
            height: 1px;
            background-color: $white;
            position: absolute;
            top: calc(50% + 1px);
            left: calc(50% + 2px);
            transform: translate(-59%, 0);
          }


        }

        .swiper-button-prev {
          border-right: 1px solid $white;
        }

        .swiper-button-next {
          transform: scaleX(-1);
        }
      }
    }

    .swiper-pagination {
      position: relative;
      bottom: 0;
      line-height: 40px;

      &-bullet {
        background-color: $primary;
        opacity: .4;
      }

      &-bullet-active {
        opacity: 1;
      }
    }
  }

  .c-figure {
    padding-bottom: percentage(1350 / 1080);
  }

  &-cnt {
    width: percentage(1480 / 2560);
    padding-left: 80px;

    @include is-tablet {
      width: 100%;
      padding: 0;
    }
  }

  .c-ttl-01 {
    margin-top: 20px;

    @include is-tablet {
      margin-top: 40px;
    }

    @include is-mobile {
      margin-top: 5px;
    }
  }

  .c-desc {
    margin-top: 30px;

    @include is-mobile {
      margin-top: 20px;
    }
  }

  .c-btn {
    margin-top: 40px;

    @include is-tablet {
      text-align: center;
    }

    @include is-mobile {
      margin-top: 25px;
    }
  }
}

.p-index-voice {
  padding: 100px 0;
  background-color: $sub;

  @include is-mobile {
    padding: 50px 0 80px;
  }

  &-inner {
    padding: 0 80px;

    @include is-mobile {
      padding: 0 15px;
    }
  }

  &-cnt {
    display: flex;

    @include is-tablet {
      display: block;
    }
  }

  .c-ttl-01 {
    padding-right: 80px;
    width: percentage(1080 / 2560);

    @include is-tablet {
      width: 100%;
    }
  }

  .c-desc {
    margin-top: 20px;
    width: percentage(1480 / 2560);

    @include is-tablet {
      width: 100%;
    }
  }

  &-slide {
    padding-top: 50px;
    // z-index: 0;

    @include is-mobile {
      padding-top: 30px;
    }
  }

  &-items {
    transition-timing-function: linear;
  }

  &-item {
    width: 300px;
    background-color: $white;
    margin-right: 30px;

    @include is-mobile {
      width: 250px;
      margin-right: 15px;
    }

    &-link {
      padding: 30px 30px 24px;
      display: block;
      transition: $transition;

      @include is-mobile {
        padding: 15px 15px 18px;
      }

      &:hover {
        @include is-laptop {
          opacity: $opacity;
        }
      }
    }

    &-figure {
      .c-figure {
        padding-bottom: percentage(624 / 500);
      }
    }

    &-txt {
      @include fz(16);
      @include line-clamp(3);
      min-height: 106px;
      line-height: 2;
      font-family: $font-min;
      color: $primary-txt;
      padding-top: 10px;

      @include is-mobile {
        @include fz(15);
        padding-top: 16px;
      }
    }
  }

  .c-btn {
    margin-top: 60px;
    text-align: center;

    @include is-mobile {
      margin-top: 35px;
    }
  }
}

.p-index-flow,
.p-index-faq {
  padding: 0 $side-padding;
  background-color: $sub;

  @include is-mobile {
    padding: 0 $side-padding-mobile;
  }

  & + & {
    padding-top: 40px;
    padding-bottom: 150px;

    @include is-mobile {
      padding-top: 16px;
      padding-bottom: 80px;
    }
  }

  &-inner {
    padding: 80px;
    position: relative;
    color: $white;
    display: flex;

    @include is-tablet {
      display: block;
    }

    @include is-mobile {
      padding: 40px 15px;
    }
  }

  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $primary;
  }

  .c-figure {
    height: 100%;
  }

  &-cnt {
    position: relative;
    z-index: 1;
    padding-left: 80px;
    width: percentage(1400 / 2240);

    @include is-tablet {
      width: 100%;
      padding: 40px 0 0;
    }

    @include is-mobile {
      padding: 16px 0 0;
    }
  }

  .c-ttl-01 {
    margin-top: 20px;
    color: $white;
    width: percentage(840 / 2240);

    @include is-tablet {
      width: 100%;
      margin-top: 0;
    }
  }

  .c-desc {
    margin-top: 0;
  }

  .c-btn {
    margin-top: 40px;

    @include is-tablet {
      text-align: center;
    }

    @include is-mobile {
      margin-top: 20px;
    }
  }
}

.p-index-news {
  position: relative;
  padding: 0 $side-padding;
  background-color: $sub;
  // z-index: 0;

  @include is-mobile {
    padding: 0;
  }

  &-inner {
    z-index: 1;
    padding: 0;
    position: relative;
    color: $white;
  }

  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $primary;
  }

  &-cnt {
    position: relative;
    z-index: 1;
    width: percentage(1440 / 2560);
    min-width: 720px;
    background-color: rgba($primary-dark, .6);
    padding: 80px;

    @include is-tablet {
      min-width: 100%;
    }

    @include is-mobile {
      padding: 50px 15px;
    }
  }

  .c-ttl-01 {
    color: $white;
  }

  &-posts {
    padding-top: 16px;

    @include is-mobile {
      padding-top: 6px;
    }
  }

  &-post {
    & + & {
      border-top: 1px solid $white;
    }
  }

  .c-post {
    &-link {
      color: $white;

      &:hover {
        @include is-laptop {
          background-color: rgba($primary, $opacity);
        }
      }
    }

    &-ttl {
      @include line-clamp(1);
      color: $white;
    }

    &-cat-name {
      color: $white;
    }
  }

  .c-btn {
    margin-top: 24px;

    @include is-tablet {
      text-align: center;
    }

    @include is-mobile {
      margin-top: 8px;
    }
  }
}

