@charset "UTF-8";

/*----------------------------------------
c-breadlist
----------------------------------------*/
.c-breadlist {
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &-container {
    padding: 32px 80px 0;

    @include is-mobile {
      padding: 15px;
    }
  }

  &-item {
    position: relative;
    white-space: pre;

    @include is-mobile {
      @include fz(14);
    }

    &:not(:last-child) {
      margin-right: 18px;
      padding-right: 26px;

      @include is-mobile {
        margin-right: 10px;
        padding-right: 18px;
      }

      &::after {
        content: "/";
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  &-link {
    transition: $transition;

    &:hover {
      @include is-laptop {
        opacity: $opacity;
      }
    }
  }
}
