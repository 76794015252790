@charset "UTF-8";

/*----------------------------------------
c-pagetop
----------------------------------------*/

.c-pagetop {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
  width: 50px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  pointer-events: none;

  &.is-active {
    // scss-lint:disable ImportantRule
    opacity: 1 !important;
    pointer-events: auto;
  }

  &-btn {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    outline: none;
    // border: 1px solid $black;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: $transition;
    background-color: rgba($white, .8);

    .is-active & {
      opacity: 1;
    }

    //mark
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: calc(50% - 3px);
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid $primary;
      border-bottom: 0;
      border-right: 0;
      transform: translateX(-50%) rotate(45deg);

      @include is-mobile {
        width: 8px;
        height: 8px;
        top: calc(50% - 2px);
      }
    }

    &:hover {
      @include is-laptop {
        opacity: $opacity;
      }
    }
  }
}
