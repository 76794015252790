@charset "UTF-8";

/*----------------------------------------
c-figure
----------------------------------------*/

.c-figure {
  line-height: 0;
  font-size: 0;
  position: relative;
  overflow: hidden;

  &-img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &-caption {
    @include fz(15);
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 1.5;
    padding: 8px 20px;
    max-width: 75%;
    color: $white;
    z-index: 1;

    @include is-mobile {
      @include fz(12);
      padding: 7px 15px;

    }

    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: $primary;
      opacity: .8;
      z-index: -1;
    }
  }
}
