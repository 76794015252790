@charset "UTF-8";

/*----------------------------------------
faq
----------------------------------------*/

.p-faq {
  padding: 74px 0 80px;

  @include is-mobile {
    padding: 30px 0 80px;
  }

  &-inner {
    max-width: 1000px;
    margin: 0 auto;
  }

  &-section {
    background-color: $sub;
    padding: 40px 40px 30px;

    @include is-mobile {
      padding: 25px 15px;
    }

    & + & {
      margin-top: 40px;

      @include is-mobile {
        margin-top: 30px;
      }
    }
  }

  &-ques {
    @include fz(23);
    line-height: 1.5;
  }

  &-answ {
    margin: 0;
    padding: 24px 0 0;

    @include is-mobile {
      padding-top: 18px;
    }
  }
}
