@charset "UTF-8";

/*----------------------------------------
c-btn
----------------------------------------*/
.c-btn {
  &-link {
    @extend %button-style;

    // &[target="_blank"] {
    //   padding-left: 42px;

    //   @include is-mobile {
    //     padding-left: 20px;
    //   }

    //   svg {
    //     min-width: 23px;
    //     width: 23px;
    //     height: 17px;
    //     margin: 0 0 4px 12px;

    //     @include is-mobile {
    //       min-width: 15px;
    //       height: 20px;
    //       width: 15px;
    //       margin: 0 0 4px 6px;
    //     }
    //   }
    // }

    .-bdr & {
      background-color: transparent;
      border: 1px solid $white;
    }
  }
}

// .c-btn02 {
//   @include fz(15);
//   @include ls(.5);
//   background: $primary;
//   padding: 15px 12px 12px;
//   color: $white;
//   font-weight: $medium;
//   min-width: 320px;
//   min-height: 60px;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   transition: $transition;
//   cursor: pointer;

//   @include is-mobile {
//     @include fz(13);
//     min-width: 100%;
//   }

//   &.-white {
//     background: $white;
//     color: $main-txt;
//   }

//   &:hover {
//     @include is-laptop {
//       opacity: $opacity;
//     }
//   }
// }

%button-style {
  @include fz(17);
  @include ls(2.4);
  background-color: $primary;
  font-family: $font-en;
  color: $white;
  padding: 13px 20px;
  display: inline-block;
  transition: $transition;
  cursor: pointer;
  min-width: 260px;
  // min-height: 60px;
  text-align: center;

  @include is-mobile {
    @include fz(14);
    @include ls(2);
    min-width: 230px;
    padding: 16px 20px;
  }

  &:hover {
    background-color: $primary;

    @include is-laptop {
      opacity: $opacity;
    }
  }


}
