@charset "UTF-8";

/*----------------------------------------
g-ham
----------------------------------------*/
body {
  &[data-menu="active"] {
    position: fixed;
    width: 100%;

    @include is-laptop {
      padding-right: 10px;
    }
  }
}

.l-container {
  &::before {
    background: rgba($black, 0);
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    transition: $transition;
    z-index: 1;

    [data-menu="active"] & {
      background: rgba($black, .5);
      pointer-events: all;
    }
  }
}

.g-ham {
  background-color: $primary;
  display: none;
  width: $header-height;
  height: $header-height;
  // border: 1px solid $black;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  // transform: translateY(-50%);

  @include is-humberger {
    display: block;
  }

  @include is-mobile {
    width: $header-height-sp;
    height: $header-height-sp;
  }

  &-line {
    width: 23px;
    height: 1px;
    background: $white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: $transition;

    &:nth-child(1) {
      top: calc(50% - 8px);

      [data-menu="active"] & {
        top: 50%;
        transform: translateX(-50%) rotate(135deg);
      }
    }

    &:nth-child(2) {
      top: 50%;

      [data-menu="active"] & {
        opacity: 0;
        transform: translateX(-50%) rotate(180deg);
      }
    }

    &:nth-child(3) {
      top: calc(50% + 8px);

      [data-menu="active"] & {
        top: 50%;
        transform: translateX(-50%) rotate(-135deg);
      }
    }

  }
}
