@charset "UTF-8";

/*----------------------------------------
about
----------------------------------------*/

.p-about {
  padding: 74px 0 80px;

  @include is-mobile {
    padding: 30px 0 80px;
  }

  &-inner {
    max-width: 1240px;
    // max-width: 1120px;
    margin: 0 auto;
  }

  &-section {
    display: flex;

    @include is-humberger {
      display: block;
    }

    & + & {
      margin-top: 80px;

      @include is-mobile {
        margin-top: 54px;
      }
    }

    &:nth-child(odd) {
      .p-about-cnt {
        padding-left: 80px;

        @include is-humberger {
          padding: 0;
        }
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .p-about-cnt {
        padding-right: 80px;

        @include is-humberger {
          padding: 0;
        }
      }
    }

  }

  &-figure {
    width: percentage(1480 / 2560);

    @include is-humberger {
      width: 100%;
    }

    .c-figure {
      padding-bottom: percentage(832 / 1480);
    }
  }

  &-cnt {
    width: percentage(1080 / 2560);

    @include is-humberger {
      width: 100%;
    }
  }

  &-ttl {
    margin-top: 20px;

    @include is-humberger {
      margin-top: 40px;
    }

    @include is-mobile {
      margin-top: 25px;
    }
  }

  &-desc {
    margin-top: 30px;

    @include is-mobile {
      margin-top: 20px;
    }
  }
}
