@charset "UTF-8";

/*----------------------------------------
js-animate
----------------------------------------*/

.js-animate {
  $transition: transform 2s $ease, opacity 2s $ease;

  &-fade-in {
    transition: $transition;
    opacity: 0;

    &.is-active {
      opacity: 1;
    }
  }

  &-zoom-in {
    transition: $transition;
    transition-duration: .8s;
    opacity: 0;
    transform: scale(.9);

    &.is-active {
      opacity: 1;
      transform: scale(1);
    }
  }

  &-focus-in {
    transition: $transition;
    transition-duration: .8s;
    opacity: 0;
    transform: scale(1.1);

    &.is-active {
      opacity: 1;
      transform: scale(1);
    }
  }

  &-fly-in-top {
    transition: $transition;
    opacity: 0;
    transform: translateY(-30px);

    &.is-active {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-fly-in-bottom {
    transition: $transition;
    opacity: 0;
    transform: translateY(30px);

    &.is-active {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-fly-in-left {
    transition: $transition;
    opacity: 0;
    transform: translateX(-30px);

    @include hack("gteIE11") {
      opacity: 1;
    }

    &.is-active {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-fly-in-right {
    transition: $transition;
    opacity: 0;
    transform: translateX(30px);

    &.is-active {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@keyframes nav-link-enter {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes nav-link-leave {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(130%);
  }
}

@keyframes line-link-after-enter {
  0% {
    transform: translateX(-130%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes line-link-after-leave {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes circle-open {
  0% {
    transform: scale(.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes line-move {
  0% {
    transform: translateX(-10px) skewX(48deg);
  }

  100% {
    transform: translateX(0) skewX(48deg);
  }
}

@keyframes line-move-rev {
  0% {
    transform: translateX(10px) skewX(-48deg);
  }

  100% {
    transform: translateX(0) skewX(-48deg);
  }
}
