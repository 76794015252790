
/*----------------------------------------
Keyframes
----------------------------------------*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes swing {
  0% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes scrolldown {
  0% {
    bottom: 0;
  }

  100% {
    bottom: -4px;
  }
}

