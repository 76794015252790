@charset "UTF-8";

/*----------------------------------------
c-map
----------------------------------------*/

.c-map {
  width: 100%;
  position: relative;
  overflow: hidden;

  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    filter: grayscale(1);
  }

  &::before {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #a5b862;
    pointer-events: none;
    content: "";
    // opacity: .5;
    mix-blend-mode: overlay;
  }
}
