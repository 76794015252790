// @charset "UTF-8";

/*----------------------------------------
add_to_any
----------------------------------------*/

.a2a_kit {
  a {
    padding: 0;
    margin: 0 6px;
    color: $main-txt;
  }
}

.a2a_mini {
  width: 312px !important;
}

.a2a_svg {
  background: none !important;

  svg {
    width: 28px;
    height: 28px;
  }

  // svg,
  // path,
  // g {
  //   fill: $main-txt;
  // }
}

.a2a_full {
  height: 340px !important;

  &_footer {
    padding: 18px 14px !important;
  }
}

.a2a_menu {
  a {
    font-family: $font !important;
    color: $primary !important;
    transition: $transition !important;
  }
}

.a2a_mini_services {
  padding: 6px !important;
  overflow: hidden;
}

.a2a_menu_show_more_less {
  span {
    @include fz(14);
    font-family: $font !important;
  }
}

.addtoany_list a span {
  transition: $transition;
}
