@charset "UTF-8";

/*----------------------------------------
is-tel-link
----------------------------------------*/

.is-tel-link {
  cursor: default;
  pointer-events: none;

  @include is-tablet {
    pointer-events: all;
    cursor: pointer;
  }
}
