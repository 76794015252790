@charset "UTF-8";

/*----------------------------------------
c-container
----------------------------------------*/

.c-container {
  max-width: 1440px;
  margin: auto;
  padding-left: $side-padding;
  padding-right: $side-padding;

  @include is-mobile {
    padding-left: $side-padding-mobile;
    padding-right: $side-padding-mobile;
  }

  &-nopad {
    padding-right: 0;
    padding-left: 0;
  }
}
