@charset "UTF-8";

/*----------------------------------------
c-modal
----------------------------------------*/

.c-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  background: rgba($sub-bg, .9);
  transition: opacity .5s $ease, visibility .5s $ease, z-index .2s ease .3s;

  &.-open {
    left: 0;
    z-index: 100;
    opacity: 1;
    visibility: visible;
    transition: opacity .5s $ease, visibility .5s $ease;
  }

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // overflow-y: auto;
  }

  &-cnt {
    margin: auto;
    opacity: 0;
    transition-delay: .1s;
    transition: $transition;
    // max-width: 90%;
    // max-height: 90%;
    width: 90%;
    height: 90%;
    position: relative;

    .-open & {
      opacity: 1;
    }

    &-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
    }
  }

  &-close {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 45px;
    height: 45px;
    background: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 1;
    // mix-blend-mode: exclusion;

    &-bar {
      position: absolute;
      // top: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: $primary-txt;
      transition: transform .3s ease;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }

    &:hover &-bar:nth-child(1) {
      transform: rotate(135deg);
    }

    &:hover &-bar:nth-child(2) {
      transform: rotate(-135deg);
    }
  }
}

body[data-modal="active"] {
  overflow-y: hidden;

  @include is-laptop {
    padding-right: 10px;
  }

  .g-header {
    // z-index: 0;
    opacity: 0;
    pointer-events: none;

    @include is-laptop {
      padding-right: 10px;
    }
  }

  .c-pagetop.is-active {
    // scss-lint:disable ImportantRule
    opacity: 0 !important;
    pointer-events: none;
  }
}
