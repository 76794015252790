@charset "UTF-8";

/*----------------------------------------
menu
----------------------------------------*/

.p-menu {
  padding: 74px 0 80px;

  @include is-mobile {
    padding: 30px 0 80px;
  }

  &-inner {
    max-width: 1280px;
    margin: 0 auto;
  }

  &-section {
    background-color: $sub;
    padding: 60px 80px 80px;

    @include is-mobile {
      padding: 25px 15px 30px;
    }

    & + & {
      margin-top: 80px;

      @include is-mobile {
        margin-top: 50px;
      }
    }
  }

  &-ttl {
    margin-bottom: 32px;
  }
}

.p-menu-lay {
  $this: &;

  & + & {
    margin-top: 30px;

    @include is-mobile {
      margin-top: 25px;
    }
  }

  .c-ttl-03 {
    @include fz(24);

    @include is-mobile {
      @include fz(18);
      padding-bottom: 3px;
    }
  }

  .c-figure {
    padding-bottom: percentage(720 / 1080);
  }

  .c-ttl-03 + .c-figure {
    margin-top: 10px;
  }

  &-table {
    margin-top: 30px;

    @include is-mobile {
      margin-top: 25px;
    }

    & + & {
      margin-top: 12px;
    }

    &-caption {
      @include fz(16);
      padding: 0 10px;
      font-family: $font-min;
      color: $primary-txt;
      background-color: $sub;

      @include is-mobile {
        @include fz(14);
        margin-top: 6px;
      }
    }

    &-row {
      align-items: center;

      & + & {
        border-top: 1px solid $primary-txt;
        padding-top: 20px;
      }
    }

    &-head {
      @include fz(16);
      line-height: 1.5;
      font-family: $font-min;
      color: $primary-txt;

      @include is-mobile {
        @include fz(14);
      }
    }

    &-data {
      .p-menu-lay-list + .c-desc {
        margin-top: 8px;
      }
    }
  }

  &-list {
    &-item {
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 11px;
        width: 8px;
        height: 8px;
        background-color: $primary;
        z-index: 2;
      }

      & + & {
        margin-top: 4px;
      }
    }
  }

  &.-fig01 {
    #{$this}-inner {
      display: block;
    }

    #{$this}-cnt + #{$this}-cnt {
      margin-top: 30px;

      @include is-mobile {
        margin-top: 25px;
      }
    }
  }

  &.-fig02 {
    #{$this}-inner {
      display: flex;
      margin: 0 -20px;

      @include is-tablet {
        display: block;
        margin: 0;
      }
    }

    #{$this}-cnt {
      width: calc(50% - 40px);
      margin: 0 20px;

      @include is-tablet {
        width: 100%;
        margin: 0;
      }
    }

    #{$this}-cnt + #{$this}-cnt {
      @include is-tablet {
        margin-top: 30px;
      }

      @include is-mobile {
        margin-top: 25px;
      }
    }
  }

  &.-overview {
    background-color: $white;
    padding: 40px 40px 24px;
    // margin-top: 40px;

    @include is-mobile {
      padding: 15px;
      // margin-top: 25px;
    }

    #{$this}-inner {
      display: block;
    }

    #{$this}-table {
      &-row {
        padding: 16px 8px;
        display: flex;

        @include is-tablet {
          display: block;
          padding: 14px 8px;
        }
      }

      &-head {
        width: 320px;
        padding-right: 20px;

        @include is-tablet {
          width: 100%;
          padding: 0;
        }
      }

      &-data {
        width: calc(100% - 320px);

        @include is-tablet {
          width: 100%;
          padding: 6px 0 0;
        }
      }
    }
  }

  &.-fig03 {
    #{$this}-inner {
      display: flex;
      margin: 0 -20px;

      @include is-tablet {
        display: block;
        margin: 0;
      }
    }

    .c-ttl-03 + #{$this}-inner {
      margin-top: 10px;
    }

    #{$this}-cnt {
      width: calc(50% - 40px);
      margin: 0 20px;

      @include is-tablet {
        width: 100%;
        margin: 0;
      }
    }

    // .c-figure {
    //   min-height: 100%;
    // }

    #{$this}-cnt + #{$this}-cnt {
      background-color: $white;
      padding: 30px 30px 8px;

      @include is-tablet {
        margin-top: 30px;
      }

      @include is-mobile {
        margin-top: 25px;

        padding: 15px 15px 0;
      }
    }

    #{$this}-table {
      &-row {
        padding: 14px 8px;
      }

      &-caption {
        margin-top: 30px;

        @include is-mobile {
          margin-top: 25px;
        }
      }

      &-data {
        padding: 8px 0 18px;

        @include is-mobile {
          padding: 6px 0 0;
        }
      }
    }
  }

  &.-txt {
    .c-desc {
      margin-top: 0;
    }
  }
}

.p-menu-special {
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  padding: 20px;

  @include is-mobile {
    max-width: calc(100% - 30px);
    padding: 15px;
  }

  &-inner {
    padding: 30px 30px 20px;
    background-color: $white;

    @include is-mobile {
      padding: 15px;
    }
  }

  .c-desc {
    margin-top: 12px;
  }
}

.p-menu-figure {
  .c-ttl-03 + & {
    margin-top: 10px;
  }

  .c-figure-caption {
    max-width: 61%;

    @include is-mobile {
      max-width: 68.9%;
    }
  }

  &-ctrl {
    position: absolute;
    bottom: 40px;
    justify-content: flex-end;
    width: 100%;
    display: none;

    .swiper-container-initialized .swiper-wrapper + & {
      display: flex;
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      width: 60px;
      height: 60px;
      background-color: $primary;
      margin: 0;
      top: 0;
      left: 0;

      @include is-mobile {
        width: 32px;
        height: 32px;
      }

      &::before {
        content: "";
        width: 5px;
        height: 1px;
        background-color: $white;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 10px);

        @include is-mobile {
          left: calc(50% - 6px);
        }
      }

      &::after {
        content: "";
        width: 20px;
        height: 1px;
        background-color: $white;
        position: absolute;
        top: calc(50% + 1px);
        left: calc(50% + 2px);
        transform: translate(-59%, 0);

        @include is-mobile {
          width: 13px;
        }
      }


    }

    .swiper-button-prev {
      border-right: 1px solid $white;
    }

    .swiper-button-next {
      transform: scaleX(-1);
    }
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    line-height: 40px;

    &-bullet {
      background-color: $primary;
      opacity: .4;
    }

    &-bullet-active {
      opacity: 1;
    }
  }
}
