@charset "UTF-8";

/*----------------------------------------
flow
----------------------------------------*/

.p-flow {
  padding: 74px 0 80px;

  @include is-mobile {
    padding: 30px 0 80px;
  }

  &-inner {
    max-width: 1000px;
    margin: 0 auto;
  }

  &-section {
    background-color: $sub;
    padding: 40px;

    @include is-mobile {
      padding: 25px 15px 30px;
    }

    & + & {
      margin-top: 60px;

      @include is-mobile {
        margin-top: 30px;
      }
    }
  }

  &-ttl {
    margin-bottom: 32px;

    @include is-mobile {
      margin-bottom: 25px;
    }
  }

  &-acco {
    background-color: $white;

    & + & {
      margin-top: 30px;

      @include is-mobile {
        margin-top: 20px;
      }
    }

    &-ttl {
      padding: 25px 60px 25px 25px;
      position: relative;
      transition: $transition;

      @include is-mobile {
        padding: 20px 60px 20px 16px;
      }

      &:hover {
        @include is-laptop {
          background-color: $main-bg;
        }

        &::before {
          @include is-laptop {
            transform: rotate(90deg);
          }
        }

        &::after {
          @include is-laptop {
            transform: rotate(180deg);
          }
        }
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 35px;
        background-color: $primary-txt;
        width: 16px;
        height: 1px;
        transition: $transition;
        opacity: 1;

        @include is-mobile {
          right: 15px;
        }
      }

      &::before {
        .is-open & {
          transform: rotate(180deg);
        }
      }

      &::after {
        transform: rotate(90deg);

        .is-open & {
          opacity: 0;
          transform: rotate(180deg);
        }
      }

      .c-ttl-03 {
        @include is-mobile {
          @include fz(15);
        }
      }
    }

    &-target {
      padding: 0 25px 25px;

      @include is-mobile {
        padding: 0 15px 25px;
      }

      b,
      strong {
        font-weight: 700;
        color: $primary-txt;
        background-image: linear-gradient(transparent 0%, transparent 60%, $sub 60%);
      }

      small {
        font-size: 80%;
      }
    }
  }

  &-list {
    padding: 20px 0 0;
    border-top: 1px solid $primary-txt;

    &-item {
      padding-left: 20px;
      position: relative;
      counter-increment: order;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 11px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $primary;
        z-index: 2;

        @include is-mobile {
          top: 9px;
          width: 7px;
          height: 7px;
        }
      }

      & + & {
        margin-top: 8px;
      }
    }
  }

  &-o-list {
    padding: 20px 0 0;
    border-top: 1px solid $primary-txt;

    &.-line {
      padding-top: 0;
      border-top: 0;
    }

    &-item {
      padding: 4px 0 0 45px;
      position: relative;
      counter-increment: order;

      @include is-mobile {
        padding-top: 8px;
      }

      .-line & {
        background-color: $white;
        padding: 25px 25px 25px 70px;
      }

      &::before {
        @include fz(17);
        position: absolute;
        left: 0;
        top: 3px;
        border-radius: 50%;
        background-color: $primary;
        z-index: 2;
        color: $white;
        width: 32px;
        height: 32px;
        line-height: 1.8;
        text-align: center;
        font-family: $font-en;
        content: counter(order);
        letter-spacing: 0;

        .-line & {
          top: 25px;
          left: 25px;

          @include is-mobile {
            top: 20px;
            left: 20px;
          }
        }
      }

      &:not(:last-child) {
        &::after {
          .-line & {
            position: absolute;
            background-image: linear-gradient(#d2bdaf 50%, transparent 50%);
            background-size: 2px 4px;
            background-repeat: repeat-y;
            content: "";
            left: 40px;
            top: 56px;
            height: 100%;
            width: 2px;
            z-index: 1;

            @include is-mobile {
              left: 35px;
              top: 41px;
            }
          }
        }
      }

      & + & {
        margin-top: 12px;

        .-line & {
          margin-top: 30px;

          @include is-mobile {
            margin-top: 20px;
          }
        }
      }
    }

    &-ttl {
      @include fz(19);
      line-height: 1.6;
      font-family: $font-min;
      color: $primary-txt;

      @include is-mobile {
        @include fz(15);
      }

      b,
      strong {
        background-image: linear-gradient(transparent 0%, transparent 60%, $sub 60%);
      }
    }

    &-note {
      // margin-top: -4px;

      &::before {
        content: "※";
      }
    }
  }
}
