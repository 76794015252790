
// Mixin

@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin fz($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / ($base * .625)) + rem;
}

@mixin ls($size: 0, $base: 16) {
  letter-spacing: $size + px;
  letter-spacing: ($size / ($base * .625)) + rem;
}

@mixin ti($size: 0, $base: 16) {
  margin-right: ($size / ($base * -0.625)) + rem;
  & * {
    text-indent: 0;
  }
}

@mixin object-fit {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  font-family: 'object-fit: cover;';
  /*IE対策*/
}


/* IEハック用mixin */
@mixin hack($IE-ver: null) {
  @if $IE-ver == 'lteIE8' { // IE8以下
    @media \0screen\,screen\9 {
      @content;
    }
  }
  @else if $IE-ver == 'gteIE9' { // IE9以上
    @media screen and (min-width:0\0) {
      @content;
    }
  }
  @else if $IE-ver == 'gteIE10' { // IE10以上
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
  @else if $IE-ver == 'gteIE11' { // IE11以上
    @at-root _:-ms-fullscreen,:root & {
      @content;
    }
  }
  @else if $IE-ver == 'notIE8' { // IE8じゃないとき(他のブラウザも含める)
    @media all and (min-width: 0) {
      @content;
    }
  }
}

//TODO:Masterに追加したい
// テキストの末尾省略
@mixin text-overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 複数行テキストの末尾省略
@mixin line-clamp($line: 3) {
  @supports (-webkit-line-clamp: $line) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

