@charset "UTF-8";

/*----------------------------------------
c-pager
----------------------------------------*/

.c-pager {
  &-list {
    display: flex;

    &-container {
      margin-top: 60px;
      display: flex;
      justify-content: center;

      @include is-mobile {
        margin-top: 50px;
      }
    }

    &-item {
      // width: 32px;
      // height: 32px;
      margin: 4px 2px;
    }

    &-link {
      text-align: center;
      line-height: 32px;
      width: 32px;
      height: 32px;
      display: block;
      font-family: $font-min;
      color: $primary-txt;
      transition: $transition;

      &:hover {
        @include is-laptop {
          opacity: $opacity;
        }
      }

      &.is-current {
        background: $primary;
        color: $white;
        pointer-events: none;
      }

      .is-prev &,
      .is-next & {
        font-size: 0;
        position: relative;

        &::before {
          position: absolute;
          display: block;
          content: "";
          left: 12px;
          top: 13px;
          width: 6px;
          height: 6px;
          border: 2px solid $primary-txt;
          border-top: 0;
          border-right: 0;
        }
      }

      .is-prev & {
        &::before {
          transform: rotate(45deg);
        }
      }

      .is-next & {
        &::before {
          transform: rotate(45deg) scale(-1);
        }
      }
    }

  }

}
