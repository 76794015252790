@charset "UTF-8";

/*----------------------------------------
Common
----------------------------------------*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  @include fz(15);
  @include ls(1.5);

  color: $main-txt;
  background: $main-bg;
  line-height: 2;
  font-family: $font;
  font-weight: $medium;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  // word-break: break-all;
  word-break: normal;
  overflow-wrap: break-word;
  position: relative;
  z-index: 1;

  @include is-mobile {
    @include fz(13);
  }
}

a {
  color: $main-txt;
  text-decoration: none;

  //&:not(.u-nothover):hover
  &:not([class]):hover {
    @include is-laptop {
      opacity: .7;
    }
  }

  &.u-hover {
    display: block;
  }
}

select,
input,
textarea {
  @include fz(15);
  appearance: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  font-family: $font;
}

button {
  &:focus {
    outline: none;
  }
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;

  &-track {
    border-radius: 15px;
    background: $color-track;
  }

  &-thumb {
    border-radius: 15px;
    background: $color-thumb;
  }
}

::selection {
  background-color: rgba($black, .8);
  color: $white;
}

.alignleft {
  float: right;
}

.aligncenter {
  margin: 0 auto;
  display: block;
}

.alignright {
  float: right;
}

.p-svg_hide {
  position: absolute;
  width: 0;
  height: 0;
}

.is-link-none {
  cursor: default;
  pointer-events: none;
}

/*----------------------------------------
Media
----------------------------------------*/

.is-pc,
.pc {
  display: block;

  @include is-mobile {
    display: none;
  }
}

.is-sp,
.sp {
  display: none;

  @include is-mobile {
    display: block;
  }
}

.is-laptop,
.laptop {
  @include is-tablet {
    display: none;
  }
}

.is-tablet,
.tablet {
  display: none;

  @include is-tablet {
    display: block;
  }
}

.is-tablet-up,
.tablet-up {
  display: none;

  @include is-tablet-up {
    display: block;
  }
}

.is-hum,
.hum {
  display: none;

  @include is-humberger {
    display: block;
  }
}

/*----------------------------------------
layout
----------------------------------------*/

.l-subpage {
  // max-width: $subpage-content-width;
  padding: 0;
  margin: 0 auto;

  // @include is-mobile {
  //   padding: 60px 0 0;
  // }

}

.l-container {
  overflow-x: hidden;
}

.l-main {
  max-width: 100%;
  position: relative;
  z-index: 0;
}

// .refusal {
//   // text-align: right;
//   margin-top: 20px;
//   vertical-align: middle;

//   img {
//     width: 100%;
//     max-width: 285px;
//     margin-left: auto;
//   }
// }

// .gmy_business {
//   margin-top: 20px;
//   display: block;
//   text-align: center;

//   img {
//     max-height: 12px;
//     width: auto;
//   }
// }

.is-display-none {
  display: none;
}
