@charset "UTF-8";

/*----------------------------------------
accordion
----------------------------------------*/
.js-accordion {
  $this: &;

  &-btn {
    cursor: pointer;
  }

  &-btn + #{$this}-target {
    display: none;
  }

  &.is-open #{$this}-target {
    display: block;
  }
}
