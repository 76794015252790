@charset "UTF-8";

/*----------------------------------------
c-movie
----------------------------------------*/

.c-movie {

  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  video,
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }


  &-ctrl {
    display: flex;
    justify-content: flex-end;

    // @include is-mobile {
    //   display: none;
    // }

    &-btn {
      @include fz(12);
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 0 50%;
      padding: 7px 0 6px 28px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%221em%22%20fill%3D%22%23b69681%22%20viewBox%3D%220%200%20640%20512%22%3E%3Cpath%20d%3D%22M533.6%2032.5C598.5%2085.3%20640%20165.8%20640%20256s-41.5%20170.8-106.4%20223.5c-10.3%208.4-25.4%206.8-33.8-3.5s-6.8-25.4%203.5-33.8C557.5%20398.2%20592%20331.2%20592%20256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8%2033.8-3.5zM473.1%20107c43.2%2035.2%2070.9%2088.9%2070.9%20149s-27.7%20113.8-70.9%20149c-10.3%208.4-25.4%206.8-33.8-3.5s-6.8-25.4%203.5-33.8C475.3%20341.3%20496%20301.1%20496%20256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8%2033.8-3.5zm-60.5%2074.5C434.1%20199.1%20448%20225.9%20448%20256s-13.9%2056.9-35.4%2074.5c-10.3%208.4-25.4%206.8-33.8-3.5s-6.8-25.4%203.5-33.8C393.1%20284.4%20400%20271%20400%20256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8%2033.8-3.5zM301.1%2034.8C312.6%2040%20320%2051.4%20320%2064V448c0%2012.6-7.4%2024-18.9%2029.2s-25%203.1-34.4-5.3L131.8%20352H64c-35.3%200-64-28.7-64-64V224c0-35.3%2028.7-64%2064-64h67.8L266.7%2040.1c9.4-8.4%2022.9-10.4%2034.4-5.3z%22%2F%3E%3C%2Fsvg%3E");
      min-width: 56px;
      text-align: right;
      background-size: auto 16px;

      &.-off {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%221em%22%20fill%3D%22%23b69681%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20d%3D%22M320%2064c0-12.6-7.4-24-18.9-29.2s-25-3.1-34.4%205.3L131.8%20160H64c-35.3%200-64%2028.7-64%2064v64c0%2035.3%2028.7%2064%2064%2064h67.8L266.7%20471.9c9.4%208.4%2022.9%2010.4%2034.4%205.3S320%20460.6%20320%20448V64z%22%2F%3E%3C%2Fsvg%3E");
      }
    }
  }
}
