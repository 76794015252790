
/*----------------------------------------
Extend
----------------------------------------*/

%text-style-reset {
  strong,
  b {
    font-weight: bold;
  }

  u {
    text-decoration: underline;
  }

  i,
  em {
    font-style: italic;
  }

  small {
    font-size: 80%;
  }

  s,
  strike {
    text-decoration: line-through;
  }

  sup {
    font-size: 60%;
    vertical-align: super;
  }
}
