@charset "UTF-8";

/*----------------------------------------
404
----------------------------------------*/

.p-404 {
  padding: 74px 0 80px;

  @include is-mobile {
    padding: 30px 0 80px;
  }

  &-inner {
    max-width: 1000px;
    margin: 0 auto;
  }

  &-section {
    background-color: $sub;
    padding: 64px 80px 70px;

    @include is-mobile {
      padding: 25px 15px 30px;
    }
  }

  &-ttl {
    text-align: center;
  }

  .c-desc {
    margin-top: 24px;
    text-align: center;

    @include is-mobile {
      text-align: left;
    }
  }
}
