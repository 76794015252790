@charset "UTF-8";

/*----------------------------------------
contact
----------------------------------------*/

.p-contact {
  background-color: $gray;
  border: 1px solid $black;
  border-left: 0;
  border-right: 0;
  padding-top: 120px;
  padding-bottom: 160px;

  @include is-mobile {
    padding-top: 60px;
    padding-bottom: 70px;
  }

  &-section {
    @include is-mobile {
      padding: 0 20px;
    }

    & + & {
      margin-top: 75px;

      @include is-mobile {
        margin-top: 25px;
      }
    }
  }

  &-totop {
    margin-top: 50px;
    text-align: center;

    @include is-mobile {
      margin-top: 35px;
    }
  }
}

.p-contact-form {
  &-wrap {
    margin: 60px auto 0;
    max-width: 800px;

    @include is-mobile {
      margin: 45px auto 0;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .error {
    @include fz(14);
    margin-top: 5px;
    font-weight: bold;
    color: #ef0b00;
  }

  &-item {
    position: relative;

    &:not(:first-child) {
      margin-top: 18px;

      @include is-mobile {
        margin-top: 15px;
      }
    }
  }

  &-ttl {
    position: absolute;
    font-family: $font-en;
    line-height: 1;
    padding: 12px 15px 0;
    pointer-events: none;
  }

  &-val {
    display: inline-block;
    margin-right: 8px;
    padding: 0 8px;
    background: $primary;
    color: $white;
  }

  &-val.is-any {
    background: $color-border;
  }

  &-select {
    position: relative;
    width: 100%;
    padding: 18px 30px;
    background-color: $white;

    @include is-mobile {
      padding: 18px;
    }
  }

  &-txt {
    @include fz(16);
    width: 100%;
    padding: 8px 20px 4px 100px;
    border-bottom: 1px solid $black;
    background-color: transparent;

    @include is-mobile {
      padding-left: 70px;
    }
  }

  &-txtarea {
    width: 100%;
    height: 150px;
    padding: 40px 15px 15px;
    background-color: transparent;
    resize: vertical;
    vertical-align: top;
    border: 1px solid $black;
  }

  &-privacy {
    text-align: center;
    padding-top: 44px;
    margin-bottom: -8px;

    @include is-mobile {
      padding-top: 30px;
    }

    &-link {
      text-decoration: underline;
    }

    &-check {
      padding-top: 8px;
    }
  }

  &-submit {
    padding: 24px 0 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include is-mobile {
      display: block;
      padding-top: 16px;
    }

    &-item {
      padding: 20px 15px 0;
      text-align: center;

      @include is-mobile {
        padding: 20px 25px 0;
      }
    }
  }

  &-desc {
    &.-select {
      position: relative;

      &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f0d7";
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 20px;
        pointer-events: none;

        @include is-mobile {
          right: 10px;
        }

        .mw_wp_form_preview & {
          display: none;
        }
      }
    }
  }

  ::placeholder {
    color: $gray;
  }

  .mwform-checkbox-field-text {
    position: relative;
    display: inline-block;
    padding: 0 0 0 32px;

    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border: 1px solid $main-txt;
      border-radius: 0;
      background: $white;
    }

    &::after {
      position: absolute;
      display: inline-block;
      content: "";
      top: 11px;
      left: 8px;
      width: 4px;
      height: 10px;
      border-right: 2px solid $primary;
      border-bottom: 2px solid $primary;
      opacity: 0;
      transform: rotate(45deg);

      @include is-mobile {
        top: 8px;
      }
    }
  }

  .mwform-radio-field {
    display: inline-block;
    padding-right: 16px;

    &-text {
      position: relative;
      display: inline-block;
      padding-left: 32px;
      line-height: 1.5;

      &::before {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        background: $white;
        content: "";
        top: calc(50% - 12px);
        left: 0;
        width: 24px;
        height: 24px;
        border: 1px solid $color-border;
      }

      &::after {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        content: "";
        top: calc(50% - 6px);
        left: 6px;
        width: 12px;
        height: 12px;
        background: $primary;
        opacity: 0;
        transition: opacity .5s cubic-bezier(.19, 1, .22, 1), transform .5s cubic-bezier(.19, 1, .22, 1);
        transform: scale3d(.8, .8, 1);
      }
    }
  }
}

input[type="radio"]:checked + .mwform-radio-field-text::after {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

input:checked + .mwform-checkbox-field-text::before {
  opacity: 1;
}

input:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
}

// form input
.mw_wp_form_input {
  .is-input {
    display: block;
  }

  .is-preview {
    display: none;
  }

  .is-complete {
    display: none;
  }

  .-message {
    margin-top: 56px;

    @include is-mobile {
      margin-top: 50px;
    }
  }
}

// form confirm
.mw_wp_form_confirm {
  .is-input {
    display: none;
  }

  .is-preview {
    display: block;
  }

  .is-complete {
    display: none;
  }

  .p-contact-form {
    &-desc {
      display: block;
      padding: 30px 15px 0;
      min-height: 40px;
    }
  }
}

// form complete
.mw_wp_form_complete {
  .is-input {
    display: none;
  }

  .is-preview {
    display: none;
  }

  .is-complete {
    display: block;
  }
}

// form error
.mw_wp_form_send_error {
  position: relative;
  z-index: 5;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}
