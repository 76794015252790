@charset "UTF-8";

/*----------------------------------------
select
----------------------------------------*/
.c-select {
  position: relative;

  select {
    background-color: $sub;
    border-radius: 3px;
    width: 100%;
    padding: 15px;
    color: $main-txt;
  }

  &::after {
    width: 7px;
    height: 7px;
    border: 2px solid $main-txt;
    border-top: 0;
    border-left: 0;
    content: "";
    transform: rotate(45deg) translateY(-6px);
    display: block;
    right: 16px;
    position: absolute;
    top: 50%;
    pointer-events: none;
  }
}
