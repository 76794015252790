@charset "UTF-8";

/*----------------------------------------
c-ttl
----------------------------------------*/

.c-ttl-01 {
  position: relative;
  z-index: 1;
  font-family: $font-min;
  color: $primary-txt;

  &.-sp-flex {
    @include is-tablet {
      display: flex;
    }
  }

  &-en {
    @include fz(22);
    @include ls(3);
    position: relative;
    display: block;
    line-height: 1.6;
    padding: 15px 0;

    @include is-mobile {
      @include fz(17);
      padding: 13px 0;
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      width: 48px;
      height: 1px;
      content: "";
      background-color: currentColor;

      @include is-mobile {
        width: 38px;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &-ja {
    @include fz(32);
    @include ls(6);
    display: block;
    line-height: 1.5;
    padding-top: 20px;

    @include is-mobile {
      @include fz(21);
      @include ls(3);
      padding-top: 8px;
    }

    .-sp-flex & {
      @include is-tablet {
        padding: 8px 0 0 30px;
      }

      @include is-mobile {
        padding: 10px 0 0 20px;
      }
    }
  }

}

.c-ttl-02 {
  @include fz(32);
  text-align: center;
  position: relative;
  z-index: 1;
  font-family: $font-min;
  color: $primary-txt;

  @include is-mobile {
    @include fz(26);
  }
}

.c-ttl-03 {
  @include fz(23);
  position: relative;
  text-align: left;
  z-index: 1;
  font-family: $font-min;
  padding-left: 20px;
  color: $primary-txt;
  line-height: 1.5;

  @include is-mobile {
    @include fz(18);
    padding-left: 18px;
  }

  &.-small {
    @include fz(19);
    line-height: 1.6;

    @include is-mobile {
      @include fz(17);
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    background-color: $primary;

    @include is-mobile {
      width: 5px;
    }
  }
}
