
// Media

// breakpoint
$breakpoint-max: 1025px;
$breakpoint-tablet: 1024px;
$breakpoint-tb-min: 768px;
$breakpoint-min: 767px;
$breakpoint-extra-min: 374px;
$humberger: 1200px;

// PC
@mixin is-laptop() {
  @media screen and (min-width: $breakpoint-max) {
    @content;
  }
}

// Humberger Menu
@mixin is-humberger() {
  @media screen and (max-width: $humberger) {
    @content;
  }
}

// Tablet
@mixin is-tablet() {
  @media screen and (max-width: $breakpoint-tablet) {
    @content;
  }
}

// Tablet up
@mixin is-tablet-up() {
  @media screen and (min-width: $breakpoint-tb-min) {
    @content;
  }
}

// Mobile
@mixin is-mobile() {
  @media screen and (max-width: $breakpoint-min) {
    @content;
  }
}

// Mobile Small
@mixin is-mobile-small() {
  @media screen and (max-width: $breakpoint-extra-min) {
    @content;
  }
}

// custom
@mixin mediaquery-max($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

// custom
@mixin mediaquery-min($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}
