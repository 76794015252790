@charset "UTF-8";

/*----------------------------------------
c-subpage
----------------------------------------*/

.c-subpage {
  &-header {
    margin-top: $header-height;
    z-index: 0;
    position: relative;

    @include is-mobile {
      margin-top: $header-height-sp;
    }

    &-main {
      padding: 30px 80px 24px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 300px;
      background-color: $primary;

      @include is-mobile {
        min-height: 196px;
        padding: 32px;
      }
    }

    &-bg.c-figure {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-ttl {
      &.c-ttl-01 {
        color: $white;
      }
    }
  }

}
