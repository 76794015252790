@charset "UTF-8";

/*----------------------------------------
c-desc
----------------------------------------*/

.c-desc {
  & + & {
    margin-top: 20px;
  }

  b,
  strong {
    font-weight: 700;
    color: $primary-txt;
    background-image: linear-gradient(transparent 0%, transparent 60%, $sub 60%);
  }
}
