@charset "UTF-8";

/*----------------------------------------
work
----------------------------------------*/

.p-work {
  padding: 55px $side-padding 80px;

  @include is-mobile {
    padding: 32px $side-padding-mobile 80px;
  }

  &-inner {
    max-width: 1600px;
    padding: 0;
    margin: 0 auto;
  }

  &-nav {
    &-items {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      border-bottom: 1px solid $main-txt;

      @include is-tablet {
        display: none;
      }

      &-sp {
        display: none;

        @include is-tablet {
          display: block;
        }
      }
    }

    &-item {
      display: block;
    }

    &-btn {
      border-bottom: 2px solid transparent;
      display: block;
      padding: 15px 20px;
      transition: $transition;

      .is-current & {
        color: $primary-txt;
        border-bottom: 2px solid $primary-txt;
      }

      &:hover {
        @include is-laptop {
          color: $primary;
          border-color: currentColor;
        }
      }

    }

    &-select {
      width: 100%;
    }
  }

  &-section {
    margin-top: 60px;

    @include is-mobile {
      margin-top: 30px;
    }
  }

  &-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include is-mobile {
      margin: 0 -5px;
    }
  }

  &-item {
    width: 25%;
    padding: 0 10px;

    @include is-mobile {
      width: 50%;
      padding: 0 5px;
    }

    // &-link {
    //   position: relative;
    //   display: block;
    //   width: 100%;
    // }

    &:nth-child(n + 5) {
      padding-top: 20px;

      @include is-mobile {
        padding-top: 10px;
      }
    }

    &:nth-child(n + 3) {
      @include is-mobile {
        padding-top: 10px;
      }
    }

    &-link {
      position: relative;
      display: block;
      width: 100%;
      transition: $transition;
      cursor: pointer;

      &:hover {
        @include is-laptop {
          opacity: $opacity;
        }
      }
    }
  }

  .c-figure {
    padding-bottom: percentage(862 / 690);
  }
}
